<template>
  <div>
    <div v-if="item.status != 5 && item.status != 8" :class="type == 'list' ? 'btn' : ''">
      <el-button
        v-if="item.status == 3 || item.status == 4 || item.status == 7"
        type="danger"
        class="bgec"
        @click="arrangeShooting(item)"
      >
        安排服务人员 {{ needServiceOrderText }}
      </el-button>

      <el-button
        v-if="item.subStatus == 13"
        class="bg00"
        @click="completion('firstRepair', item, 'firstRepair')"
      >
        确认完成上传初修
      </el-button>
      <el-button
        v-if="item.subStatus == 13 && !item.buttonV2Dto.hideQrxxsccxBtn"
        class="bg00"
        @click="completion('firstRepair_offline', item, 'firstRepair')"
      >
        确认已线下上传初修
      </el-button>

      <el-button
        v-if="
          item.buttonV2Dto.qrxxkyBtn &&
          (item.subStatus == 11 || item.subStatus == 14 || item.subStatus == 15)
        "
        class="bg00"
        @click="completion('sample_offline', item, 'sample')"
      >
        确认用户已线下看样
      </el-button>

      <el-button v-if="item.subStatus == 18" class="bg00" @click="completion('finedRepair', item)">
        确认完成上传精修
      </el-button>
      <el-button v-if="item.subStatus == 21" class="bg00" @click="completion('layout', item)">
        确认完成上传设计版面
      </el-button>

      <el-button
        v-if="item.buttonV2Dto.qrwcqbfwBtn && item.sourceFrom !== 3"
        class="bg00"
        @click="completion('all', item)"
      >
        确认完成全部服务
      </el-button>
      <el-button v-if="item.subStatus == 24" class="bg00" @click="completion('make', item)">
        确认完成制作服务
      </el-button>
      <el-button
        v-if="item.subStatusVideo == 26"
        class="bg00"
        @click="completion('video', item, 'video')"
      >
        确认完成上传原视频
      </el-button>
      <el-button
        v-if="item.subStatusVideo == 26"
        class="bg00"
        @click="completion('video_offline', item, 'video')"
      >
        确认已线下上传原视频
      </el-button>
      <el-button v-if="item.subStatusVideo == 29" class="bg00" @click="completion('clip', item)">
        确认完成上传剪辑
      </el-button>
      <el-button
        v-if="item.subStatus == 10"
        class="bg00"
        @click="completion('uncutPhotos', item, 'uncutPhotos')"
      >
        确认完成上传原片
      </el-button>
      <el-button
        v-if="item.subStatus == 10 && !item.buttonV2Dto.hideQrxxscypBtn"
        class="bg00"
        @click="completion('uncutPhotos_offline', item, 'uncutPhotos')"
      >
        确认已线下上传原片
      </el-button>

      <el-button v-if="item.buttonV2Dto.qrqjBtn" class="bg00" @click="confirmTaking(item)">
        确认取件
      </el-button>
      <el-button
        v-if="item.buttonV2Dto.fskyBtn && (item.subStatus == 11 || item.subStatus == 14)"
        class="bg00"
        @click="remind('sample', item)"
      >
        发送看样
      </el-button>
      <el-button
        v-if="item.subStatus == 15"
        :disabled="item.buttonV2Dto.txyhkyBtn"
        type="primary"
        @click="remind('remind_sample', item)"
      >
        提醒客户看样
      </el-button>
      <el-button
        v-if="item.subStatus == 19"
        :disabled="item.buttonV2Dto.txyhckjxBtn"
        type="primary"
        @click="remind('remind_Photo', item)"
      >
        提醒用户查看精修照片
      </el-button>
      <el-button
        v-if="item.subStatus == 22"
        type="primary"
        :disabled="item.buttonV2Dto.txyhckbmsjBtn"
        @click="remind('remind_layout', item)"
      >
        提醒用户查看设计版面
      </el-button>
    </div>
    <!-- <el-button
      v-if="item.status == 3 && item.buttonV2Dto && item.buttonV2Dto.qrksfwBtn"
      type="primary"
      style="width: 100%"
      @click="orderStartService(item)"
    >
      开始服务
    </el-button> -->
    <div :class="type == 'list' ? 'btn' : ''">
      <el-button
        v-if="(item.status == 5 || item.status == 8) && item.serviceUserStatus == 0"
        type="primary"
        @click="arrangeShooting(item)"
      >
        补充服务人员
      </el-button>
      <el-button
        v-if="(item.buttonV2Dto || {}).shareEvaBtn"
        type="primary"
        @click="goSharing(item)"
      >
        评价分享
      </el-button>
    </div>
    <!-- 子服务单 -->
    <newArrangeShooting
      v-if="arrangeShootingVisible"
      :base-product-type="baseProductType"
      :current-data="item"
      :dialog-visible.sync="arrangeShootingVisible"
      @reload="onReload"
    />
    <evaluationSharing :visible.sync="isShow" :service-id="item.serviceId" @reload="onReload" />
  </div>
</template>

<script>
import {
  notifyViewSample,
  notifyRefinedRepair,
  notifyLayout,
  confirmUpload,
  confirmFinishMake,
  confirmViewSample,
  sendSample,
  checkAdditionState,
  enterFinishServer,
  getOrderDetail,
  batchDispatchByProduct,
  getNexBaseProductType,
} from '@/api/order'

import newArrangeShooting from '@/views/orderMgt/orderList/components/newArrangeShooting.vue'
import evaluationSharing from '@/views/orderMgt/orderList/components/evaluationSharing.vue'
import { orderStartServe } from '@/api/order.js'

export default {
  components: {
    newArrangeShooting,
    evaluationSharing,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
    currenTab: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userTitle: '',
      arrangeShootingVisible: false,
      currentData: {},
      timeout: null,
      baseProductType: '',
      confirmButtonType: '',
      isShow: false,
    }
  },
  computed: {
    getTitle() {
      return (e) => {
        switch (e) {
          case 2:
            return '完成拍摄安排'
          case 3:
            return '完成拍摄安排'
          case 4:
            return '完成拍摄安排'
          case 6:
            return '完成拍摄安排'
          case 12:
            return '完成初修安排'
          case 17:
            return '完成精修安排'
          case 20:
            return '完成版面设计安排'
          case 23:
            return '完成制作安排'
          case 26:
            return '完成剪辑安排'
          default:
            break
        }
      }
    },
    getBaseProductType() {
      return (e) => {
        switch (e) {
          case 2:
            return 1
          case 3:
            return 1
          case 4:
            return 1
          case 6:
            return 1
          case 12:
            return 5
          case 17:
            return 6
          case 20:
            return 7
          case 23:
            return 8
          case 26:
            return 14
          default:
            break
        }
      }
    },
    needServiceOrderText() {
      const btnDto = this.item.buttonV2Dto || {}
      return btnDto.notServerUserNum > 0 ? `(${btnDto.notServerUserNum})` : ''
    },
  },
  destroyed() {
    clearTimeout(this.timeout)
  },
  methods: {
    // 评价分享
    goSharing() {
      this.isShow = true
    },
    onReload() {
      this.confirmButtonType = ''
      this.getTableList()
    },
    getTableList() {
      if (this.type === 'list') {
        this.$emit('refresh')
      } else {
        this.$parent.getData()
      }
    },
    confirmTaking(row) {
      checkAdditionState({
        data: {
          serviceId: row.serviceId,
        },
      }).then((res) => {
        if (Number(res.data) === 0) {
          this.$alert(
            `<div style='display: flex;align-items: center;font-size:16px'><i style='margin-right:4px;color:#E6A23C;font-size:24px' class='el-icon-warning'></i>取件确认</div><div style='color:#999;padding:0 28px'>是否确认取件？确认取件后，本服务订单将完成服务，进入评价环节。</div>`,
            '确认提示',
            {
              dangerouslyUseHTMLString: true,
              showCancelButton: true,
              beforeClose: (action, e, done) => {
                if (action === 'confirm') {
                  e.confirmButtonLoading = true
                  enterFinishServer({
                    data: {
                      serviceId: row.serviceId,
                      actionType: 2,
                    },
                  })
                    .then(() => {
                      done()
                      e.confirmButtonLoading = false
                      this.$message({
                        type: 'success',
                        message: '取件成功!',
                      })
                      this.$emit('refresh')
                    })
                    .catch(() => {
                      e.confirmButtonLoading = false
                    })
                } else {
                  done()
                }
              },
            },
          )
            .then(() => {})
            .catch(() => {})
        } else {
          this.$alert(
            `<div style='display: flex;align-items: center;font-size:16px'><i style='margin-right:4px;color:#E6A23C;font-size:24px' class='el-icon-warning'></i>当前不能确认取件</div><div style='color:#999;padding:0 28px'>当前服务订单的关联加购订单有待付款或售后中的实物产品，请在产品已付款或已取消或售后完成后再确认取件。</div>`,
            '提示',
            {
              dangerouslyUseHTMLString: true,
              showCancelButton: true,
              confirmButtonText: '查看加购订单',
            },
          )
            .then(() => {
              if (this.type === 'detail') {
                this.$emit('handleTab', { name: '加购信息', checked: false })
              } else {
                this.$router.push({
                  path: '/orderDetail',
                  query: {
                    orderServiceId: row.orderServiceId,
                    serviceId: row.serviceId,
                    isServer: true,
                  },
                })
              }
            })
            .catch(() => {})
        }
      })
    },
    async completion(e, row, buttonType) {
      try {
        let tip = '完成确认'
        let content = ''
        let uploadType = 1
        let baseProductType = 1

        switch (e) {
          case 'firstRepair':
            tip = '完成上传初修确认'
            content =
              '是否确认完成上传初修？确认完成上传初修后，本服务订单所有的初修服务子订单都将完成服务。请确认本服务订单所有的初修服务子订单都已完成上传初修后再操作。'
            baseProductType = 5
            break
          case 'firstRepair_offline':
            tip = '线下上传初修确认'
            content =
              '是否确认已线下上传初修？确认已线下上传初修后，本服务订单所有的初修服务子订单都将完成服务。请确认本服务订单所有的初修服务子订单都已线下上传初修后再操作'
            uploadType = 2
            baseProductType = 5
            break
          case 'finedRepair':
            tip = '完成上传精修确认'
            content =
              '是否确认完成上传精修？确认完成上传精修后，本服务订单所有的精修服务子订单都将完成服务。请确认本服务订单所有的精修服务子订单都已完成上传精修后再操作'
            baseProductType = 6
            break
          case 'layout':
            tip = '完成上传设计版面确认'
            content =
              '是否确认完成上传设计版面？确认完成上传设计版面后，本服务订单所有的设计服务子订单都将完成服务。请确认本服务订单所有的设计服务子订单都已完成上传设计版面后再操作'
            baseProductType = 7
            break
          case 'make':
            tip = '完成制作服务确认'
            content =
              '是否确认完成制作服务？确认完成制作服务后，本服务订单所有的制作服务子订单都将完成服务。请确认本服务订单所有的制作服务子订单都已完成制作服务后再操作。'
            baseProductType = 8
            break
          case 'video':
            tip = '完成上传原视频确认'
            content =
              '是否确认完成上传原视频？确认完成上传原视频后，本服务订单所有的摄像服务子订单都将完成服务。请确认本服务订单所有的摄像服务子订单都已完成上传原视频后再操作。'
            baseProductType = 15
            break
          case 'video_offline':
            tip = '线下上传原视频确认'
            content =
              '是否确认已线下上传原视频？确认已线下上传原视频后，本服务订单所有的摄像服务子订单都将完成服务。请确认本服务订单所有的摄像服务子订单都已线下上传原视频后再操作。'
            uploadType = 2
            baseProductType = 15
            break
          case 'clip':
            tip = '完成上传剪辑确认'
            content =
              '是否确认完成上传剪辑？确认完成上传剪辑后，本服务订单所有的剪辑服务子订单都将完成服务。请确认本服务订单所有的剪辑服务子订单都已完成上传剪辑后再操作。'
            baseProductType = 14
            break
          case 'uncutPhotos':
            tip = '完成上传原片确认'
            content =
              '是否确认完成上传原片？确认完成上传原片后，本服务订单所有的摄影服务子订单都将完成服务。请确认本服务订单所有的摄影服务子订单都已完成上传原片后再操作。'
            baseProductType = 1
            break
          case 'uncutPhotos_offline':
            tip = '线下上传原片确认'
            content =
              '是否确认已线下上传原片？确认已线下上传原片后，本服务订单所有的摄影服务子订单都将完成服务。请确认本服务订单所有的摄影服务子订单都已线下上传原片后再操作。'
            uploadType = 2
            baseProductType = 1
            break
          case 'sample_offline':
            tip = '用户已线下看样确认'
            content =
              '是否确认用户已线下看样？确认用户已线下看样后，本服务订单将跳过线上看样环节，直接完成看样。'
            break
          default:
            break
        }

        if (e === 'all') {
          const res = await checkAdditionState({
            data: {
              serviceId: row.serviceId,
            },
          })

          if (Number(res.data) === 0) {
            await this.$alert(
              `<div style='display: flex;align-items: center;font-size:16px'><i style='margin-right:4px;color:#E6A23C;font-size:24px' class='el-icon-warning'></i>完成全部服务确认</div><div style='color:#999;padding:0 28px'>是否确认完成全部服务？确认完成全部服务后，本服务订单将完成服务，进入评价环节。</div>`,
              '确认提示',
              {
                dangerouslyUseHTMLString: true,
                showCancelButton: true,
                beforeClose: (action, e, done) => {
                  if (action === 'confirm') {
                    e.confirmButtonLoading = true

                    enterFinishServer({
                      data: {
                        serviceId: row.serviceId,
                        actionType: 1,
                      },
                    })
                      .then(() => {
                        done()
                        e.confirmButtonLoading = false

                        this.$message({
                          type: 'success',
                          message: '确认成功!',
                        })

                        this.$emit('refresh')
                      })
                      .catch((err) => {
                        console.log(err)
                        e.confirmButtonLoading = false
                      })

                    return
                  }

                  done()
                },
              },
            )

            return
          }

          await this.$alert(
            `<div style='display: flex;align-items: center;font-size:16px'><i style='margin-right:4px;color:#E6A23C;font-size:24px' class='el-icon-warning'></i>当前不能确认完成全部</div><div style='color:#999;padding:0 28px'>当前服务订单的关联加购订单有待付款或售后中的实物产品，请在产品已付款或已取消或售后完成后再确认完成全部服务。</div>`,
            '提示',
            {
              dangerouslyUseHTMLString: true,
              showCancelButton: true,
              confirmButtonText: '查看加购订单',
            },
          )

          if (this.type === 'detail') {
            this.$emit('handleTab', { name: '加购信息', checked: false })
          } else {
            this.$router.push({
              path: '/orderDetail',
              query: {
                orderServiceId: row.orderServiceId,
                serviceId: row.serviceId,
                isServer: true,
              },
            })
          }

          return
        }

        this.$alert(
          `<div style='display: flex;align-items: center;font-size:16px'><i style='margin-right:4px;color:#E6A23C;font-size:24px' class='el-icon-warning'></i>${tip}</div><div style='color:#999;padding:0 28px'>${content}</div>`,
          '确认提示',
          {
            dangerouslyUseHTMLString: true,
            showCancelButton: true,
            beforeClose: async (action, instance, done) => {
              try {
                if (action !== 'confirm') return
                instance.confirmButtonLoading = true
                instance.confirmButtonText = '执行中...'

                if (this.confirmButtonType === buttonType) {
                  this.arrangeShooting(row)
                  return
                }

                if (e === 'sample_offline') {
                  await confirmViewSample({
                    data: {
                      serviceId: row.serviceId,
                    },
                  })
                } else if (e === 'make') {
                  return confirmFinishMake({
                    data: row.serviceId,
                  }).then((res) => {
                    console.log(res)
                    this.$emit('refresh')
                  })
                } else {
                  await confirmUpload({
                    data: {
                      serviceId: row.serviceId,
                      uploadType,
                      baseProductType,
                    },
                  })
                }

                this.confirmButtonType = buttonType
                const detailRes = await getOrderDetail({ data: row.serviceId })
                const detailInfo = detailRes.data || {}
                const nextBaseProductType = await this.getSendBaseProductType(detailInfo)

                const otherParams = {
                  orderId: detailInfo.orderId,
                  orderProductId: detailInfo.orderProductId,
                  orderProductIdPath: detailInfo.orderProductIdPath,
                  serviceId: detailInfo.serviceId,
                  baseProductType: nextBaseProductType,
                }

                // 如果下一阶段是确认线上，线下看样，确认取件，已上传剪辑跳过
                // const subStatus = Number(detailInfo.subStatus)
                // if (
                //   (detailInfo.buttonV2Dto.qrxxkyBtn &&
                //     (subStatus === 11 || subStatus === 14 || subStatus === 15)) ||
                //   (detailInfo.buttonV2Dto.fskyBtn && (subStatus === 11 || subStatus === 14)) ||
                //   detailInfo.buttonV2Dto.qrqjBtn ||
                //   detailInfo.subStatusVideo === 30
                // ) {
                //   // 同时包含摄影，摄像的双条线订单，已上传剪辑之后，处理另一条线下一阶段订单
                //   if (detailInfo.subStatusVideo === 30 && nextBaseProductType) {
                //     await batchDispatchByProduct({ data: otherParams })
                //     this.arrangeShooting(detailInfo)
                //     return
                //   }

                //   this.$emit('refresh')
                //   return
                // }
                if (nextBaseProductType <= 0) return this.$emit('refresh')

                await batchDispatchByProduct({ data: otherParams })

                this.$message({
                  type: 'success',
                  message: '确认成功!',
                })

                this.arrangeShooting(detailInfo)
              } catch (err) {
                console.log(err)
              } finally {
                instance.confirmButtonLoading = false
                done()
              }
            },
          },
        )
      } catch (err) {
        console.log(err)
      }
    },
    orderStartService(row) {
      orderStartServe({
        data: {
          serviceItemsId: row.serviceItemId,
          storeId: row.appointmentStroeId,
          serviceId: row.serviceId,
        },
      })
        .then(() => {
          this.$message.success('开始服务成功~')
          this.$emit('refresh')
        })
        .catch((e) => {
          this.$message.error(e.description || '开始服务失败~')
        })
    },
    arrangeShooting(row, e) {
      // this.userTitle = row.buttonV2Dto.repeatVideo ? '剪辑' : this.getTitle(row.subStatus)
      this.baseProductType = e || this.getBaseProductType(row.subStatus)
      this.arrangeShootingVisible = true
    },
    async getSendBaseProductType(item) {
      const res = await getNexBaseProductType({
        data: item.serviceId,
      })
      return res.data
    },
    remind(e, row) {
      if (this.confirmButtonType === e && e === 'sample') {
        this.$message.warning('您已确认线下看样，请勿重复操作')
        return
      }

      switch (e) {
        case 'sample':
          this.$confirm('是否确认发送看样？', '确认提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              sendSample({
                data: row.serviceId,
              }).then(() => {
                this.$alert(
                  "<i style='color:#4bd863;font-size:80px;font-weight: bold;' class='el-icon-circle-check'></i><div style='padding:5px 0;color:#666;font-size:18px;font-weight: bold;'>发送看样成功</div><div>客户将收到看样短信提醒</div>",
                  {
                    center: true,
                    dangerouslyUseHTMLString: true,
                    showConfirmButton: false,
                    showClose: false,
                  },
                )
                this.timeout = setTimeout(() => {
                  this.$msgbox.close()
                  this.$emit('refresh')
                }, 1500)
              })
            })
            .catch(() => {})
          break
        case 'remind_sample':
          this.$confirm('是否确认短信提醒客户看样？', '确认提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              notifyViewSample({
                data: row.serviceId,
              }).then(() => {
                this.$message({
                  type: 'success',
                  message: '提醒成功!',
                })
                this.$emit('refresh')
              })
            })
            .catch(() => {})
          break

        case 'remind_Photo':
          this.$confirm('是否确认短信提醒客户查看精修照片？', '确认提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              notifyRefinedRepair({
                data: row.serviceId,
              }).then(() => {
                this.$message({
                  type: 'success',
                  message: '提醒成功!',
                })
                this.$emit('refresh')
              })
            })
            .catch(() => {})
          break
        case 'remind_layout':
          this.$confirm('是否确认短信提醒客户查看设计面版？', '确认提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              notifyLayout({
                data: row.serviceId,
              }).then(() => {
                this.$message({
                  type: 'success',
                  message: '提醒成功!',
                })
                this.$emit('refresh')
              })
            })
            .catch(() => {})
          break

        default:
          break
      }
    },
  },
}
</script>

<style lang="less" scoped>
.btn {
  ::v-deep .el-button {
    display: block;
    margin: 0 0 12px 0;
    width: 100%;
  }
}
</style>
