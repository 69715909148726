<template>
  <el-dialog
    class="success-server-container"
    :title="currentPopupsTitle"
    :visible="showState"
    width="600px"
    top="30vh"
    @close="hidePopups()"
  >
    <el-form v-if="type == 2" ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="核销码" prop="writeOffNo">
        <el-input v-model="form.writeOffNo" placeholder="请输入核销码"></el-input>
      </el-form-item>
      <div style="color: #f59a23">*或使用微信扫描用户提供的二维码，输入验证码进行验证</div>
      <div class="link-content">
        <span>确认完成服务验证码：</span>
        <span class="link">{{ serviceCode ? serviceCode : '--' }}</span>
        <el-button type="primary" size="mini" @click="handleCopy(serviceCode)">复制</el-button>
      </div>
    </el-form>
    <div v-if="type === 1" class="warning-content">
      <el-result icon="warning"></el-result>
      <div class="warning-info">
        <p class="warning-info-title">当前不能确认完成服务</p>
        <p class="warning-info-prompt">
          当前服务订单的关联加购订单有售后中产品，请在产品售后完成后确认完成服务。
        </p>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="hidePopups">取消</el-button>
      <el-button
        v-if="type == 2"
        type="primary"
        :loading="submitLoadingStatus"
        @click="submitForm()"
        >确认</el-button
      >
      <el-button v-if="type == 1" type="primary" @click="handleSearchAddOrder()"
        >查看加购订单</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { enterFinishServer, verPcProduct, getServeSuccessCode } from '@/api/order'

export default {
  props: {
    popupsTitle: {
      type: String,
      default: '',
    },
    showState: {
      type: Boolean,
      default: false,
    },
    currentData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      submitLoadingStatus: false,
      type: null,
      currentPopupsTitle: '',
      form: {
        writeOffNo: undefined,
      },
      rules: { writeOffNo: [{ required: true, message: '请输入核销码', trigger: 'blur' }] },
      serviceCode: '',
    }
  },
  watch: {
    showState(val) {
      if (val) {
        this.getData()
      }
    },
  },
  methods: {
    getData() {
      verPcProduct({
        data: {
          serviceId: this.currentData.serviceId,
          writeOffType: 3,
        },
      }).then((response) => {
        if (response.data === true) {
          this.currentPopupsTitle = this.popupsTitle
          this.type = 2
          getServeSuccessCode({
            data: {
              serviceId: this.currentData.serviceId,
              writeOffType: 3,
            },
          }).then((response) => {
            if (response.data) {
              this.serviceCode = response.data.writeOffNo
            }
          })
        } else {
          this.currentPopupsTitle = '提示'
          this.type = 1
        }
      })
    },

    async handleCopy(data) {
      const target = document.createElement('input') // 创建input节点
      target.value = '确认完成服务验证码：' + (data || '--') // 给input的value赋值

      document.body.appendChild(target) // 向页面插入input节点
      target.select() // 选中input
      try {
        await document.execCommand('Copy') // 执行浏览器复制命令
        this.$notify.success({ title: '成功', message: '复制成功', duration: 2000 })
      } catch {
        this.$notify.error({
          title: '成功',
          message: '当前浏览器不支持此功能，复制失败',
          duration: 2000,
        })
      }
      document.body.removeChild(target) // 删除插入的input节点
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoadingStatus = true
          const formData = JSON.parse(JSON.stringify(this.form))
          formData.serviceId = this.currentData.serviceId
          formData.writeOffType = 1
          enterFinishServer({ data: formData })
            .then((res) => {
              this.$notify.success({ title: '成功', message: '确认成功', duration: 2000 })
              if (this.currentData.inType === 'list') {
                this.$parent.getTableList()
              } else {
                this.$parent.getData()
              }
              this.hidePopups()
              this.submitLoadingStatus = false
            })
            .catch(() => {
              this.submitLoadingStatus = false
            })
        }
      })
    },
    handleSearchAddOrder() {
      if (this.currentData.inType === 'list') {
        this.$router.push({
          path: '/orderDetail',
          query: {
            orderServiceId: this.currentData.orderServiceId,
            serviceId: this.currentData.serviceId,
            isServer: true,
          },
        })
      } else {
        const currentItem = this.$parent.tabList.find((fi) => fi.checked)
        currentItem.checked = false
        this.$parent.tabList[1].checked = true
        this.$parent.currenTab = this.$parent.tabList[1]
        this.$parent.getDetailAddOrder()
        this.hidePopups()
      }
    },
    hidePopups() {
      this.serviceCode = ''
      this.$emit('hidePopups')
    },
  },
}
</script>

<style lang="scss" scoped>
.success-server-container {
  ::v-deep .el-dialog__title {
    font-size: 16px;
  }
  ::v-deep .el-dialog__body {
    padding: 24px 40px 16px 40px;
  }
  ::v-deep .el-dialog__footer {
    padding: 12px 24px;
    border-top: 1px solid #ededed;
  }
  ::v-deep .dialog-footer {
    text-align: right;
  }
  .link-content {
    margin-top: 12px;
    .link {
      margin-right: 10px;
      color: #2861e2;
      :hover {
        opacity: 0.8;
      }
    }
  }
  .warning-content {
    display: flex;
    ::v-deep .el-result {
      padding: 26px 23px;
    }
    .warning-info {
      .warning-info-title {
        font-size: 20px;
        font-weight: bold;
      }
      .warning-info-prompt {
        font-size: 16px;
        line-height: 32px;
      }
    }
  }
}
</style>
