<template>
  <div>
    <el-dialog
      title="关联服务单"
      :visible="outerVisible"
      width="800px"
      @open="openDialog"
      @close="clickCancelOk"
    >
      <template v-if="detailsInfo.status != 5 && detailsInfo.status != 8 && !resStatus">
        <el-form :model="form" label-width="80px" class="form">
          <el-form-item label="服务单ID">
            <el-input v-model="form.serviceId" placeholder="请输服务单ID"></el-input>
          </el-form-item>
          <el-form-item label="服务单号" label-width="100px">
            <el-input v-model="form.serviceNo" placeholder="请输入服务单号"></el-input>
          </el-form-item>
          <el-form-item class="buttonClass">
            <el-button icon="el-icon-search" type="primary" @click="getDataCX">搜索</el-button>
            <el-button icon="el-icon-refresh-right" @click="clickReset">重置</el-button>
          </el-form-item>
        </el-form>

        <div v-loading="loading">
          <div v-if="tableData && tableData.length > 0" class="card-max">
            <div
              v-for="(item, index) in tableData"
              :key="index"
              :class="
                item.status === 5 || item.status === 8
                  ? 'card-main-prohibit'
                  : groupServiceId === item.serviceId
                  ? 'card-main-hover'
                  : 'card-main'
              "
              @click="clickCard(item)"
            >
              <div class="card-top">
                <span>服务单号</span>
                <span>{{ item.serviceNo }}</span>
              </div>
              <div
                :class="item.status === 5 || item.status === 8 ? 'card-down-prohibit' : 'card-down'"
              >
                <span>服务单ID</span>
                <span>{{ item.serviceId }}</span>
              </div>
              <span
                :class="
                  item.status === 5 || item.status === 8
                    ? 'checked-prohibit'
                    : groupServiceId === item.serviceId
                    ? 'checked-hover-x'
                    : 'checked-x'
                "
              ></span>
            </div>
          </div>
          <el-empty v-else description="无相关数据~"></el-empty>
        </div>
      </template>

      <template v-else>
        <div class="top-main">
          <div class="title">已关联</div>
          <div v-loading="loading">
            <div v-if="groupServiceId" class="card-max">
              <template v-for="(itemck1, indexck1) in tableData">
                <div
                  v-if="groupServiceId === itemck1.serviceId"
                  :key="indexck1"
                  class="card-main-hover-ck"
                >
                  <div class="card-top">
                    <span>服务单号</span>
                    <span>{{ itemck1.serviceNo }}</span>
                  </div>
                  <div class="card-down">
                    <span>服务单ID</span>
                    <span>{{ itemck1.serviceId }}</span>
                  </div>
                  <span class="checked-text">已关联</span>
                </div>
              </template>
            </div>
            <el-empty v-else description="无相关数据~"></el-empty>
          </div>
        </div>

        <div class="top-main">
          <div class="title">未关联</div>
          <div v-loading="loading">
            <div v-if="tableData && tableData.length > 0" class="card-max">
              <template v-for="(itemck2, indexck2) in tableData">
                <div
                  v-if="groupServiceId != itemck2.serviceId && !itemck2.groupServiceId"
                  :key="indexck2"
                  class="card-main-ck"
                >
                  <div class="card-top">
                    <span>服务单号</span>
                    <span>{{ itemck2.serviceNo }}</span>
                  </div>
                  <div class="card-down">
                    <span>服务单ID</span>
                    <span>{{ itemck2.serviceId }}</span>
                  </div>
                </div>
              </template>
            </div>
            <el-empty v-else description="无相关数据~"></el-empty>
          </div>
        </div>
      </template>

      <template slot="footer">
        <template v-if="detailsInfo.status != 5 && detailsInfo.status != 8 && !resStatus">
          <el-button @click="clickCancel"> 取消 </el-button>
          <el-button type="primary" @click="clickSave"> 提交 </el-button>
        </template>

        <el-button v-else @click="clickCancelOk"> 知道了 </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getOrderQuery, relevanceGroup } from '@/api/order'

export default {
  model: {
    prop: 'outerVisible',
    event: 'changeOuterVisible',
  },
  props: {
    detailsInfo: {
      type: Object,
      default: () => {},
    },
    outerVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        serviceId: '',
        serviceNo: '',
      },
      loading: false,
      tableData: [],
      groupServiceId: '',
      resGroupServiceId: '',
      resStatus: false,
    }
  },

  methods: {
    //  打开弹框
    openDialog() {
      this.groupServiceId = this.detailsInfo.groupServiceId
      this.resGroupServiceId = this.detailsInfo.groupServiceId

      this.getTableList()
    },
    // 表格详情
    async getTableList() {
      try {
        this.loading = true
        const data = {
          data: {
            userId: this.detailsInfo.userId,
            appointmentShootStartTime: `${this.detailsInfo.appointmentShootDate} 00:00:00`,
            appointmentShootEndTime: `${this.detailsInfo.appointmentShootDate} 23:59:59`,
            serviceId: this.form.serviceId || '',
            serviceNo: this.form.serviceNo || '',
            excludeServiceId: this.detailsInfo.serviceId,
          },
          sortField: 'appointment_shoot_datetime',
          sortType: 'asc',
        }
        const res = await getOrderQuery(data)
        this.tableData = res.data.content || []

        this.resStatus = this.tableData.some((item) => {
          return (
            item.serviceId === this.resGroupServiceId && (item.status === 5 || item.status === 8)
          )
        })

        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    // 搜索
    getDataCX() {
      this.groupServiceId = this.resGroupServiceId
      this.getTableList()
    },
    // 重置
    clickReset() {
      this.form = {
        serviceId: '',
        serviceNo: '',
      }
      this.groupServiceId = this.resGroupServiceId
      this.getTableList()
    },
    // 单选
    clickCard(vals) {
      if (vals.status !== 5 && vals.status !== 8) {
        if (this.groupServiceId === vals.serviceId) {
          this.groupServiceId = ''
        } else {
          this.groupServiceId = vals.serviceId
        }
      }
    },
    // 取消
    clickCancel() {
      this.$emit('changeOuterVisible', false)
      this.form = {
        serviceId: '',
        serviceNo: '',
      }
      this.groupServiceId = this.resGroupServiceId
    },
    // 知道了
    clickCancelOk() {
      this.$emit('changeOuterVisible', false)
    },
    // 提交
    async clickSave() {
      try {
        const data = {
          data: {
            serviceId: this.detailsInfo.serviceId,
            groupServiceId: this.groupServiceId,
            secondarySubmission: false,
          },
        }
        const res = await relevanceGroup(data)
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功!',
          })
        }
        this.$emit('changeOuterVisible', false)
        this.$emit('reFresh', true)
      } catch (error) {
        if (error.code === '2') {
          this.$confirm(`${error.description}`, '温馨提示', {
            confirmButtonText: '继续提交',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(async () => {
              const data2 = {
                data: {
                  serviceId: this.detailsInfo.serviceId,
                  groupServiceId: this.groupServiceId,
                  secondarySubmission: true,
                },
              }
              const res = await relevanceGroup(data2)
              if (res.success) {
                this.$message({
                  type: 'success',
                  message: '操作成功!',
                })
              }
              this.$emit('changeOuterVisible', false)
              this.$emit('reFresh', true)
            })
            .catch((error) => console.log(error))
        } else {
          console.log(error)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-empty {
  padding: 0;
}
.form {
  display: flex;
  flex-wrap: wrap;
  .buttonClass {
    margin-left: -60px;
  }
}

.card-max {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  max-height: 220px;
  overflow-y: auto;
  padding-right: 2px;
  .card-main-prohibit {
    cursor: not-allowed;
  }
  .card-main-ck,
  .card-main-hover-ck {
    cursor: default;
  }
  .card-main,
  .card-main-hover {
    cursor: pointer;
  }

  .card-main-hover,
  .card-main-hover-ck {
    background: #e9effc;
    border: 1px solid #2861e2;
    border-radius: 4px 4px 4px 4px;
    padding: 15px 50px 15px 16px;
  }

  .card-main,
  .card-main-prohibit,
  .card-main-ck {
    background: #f8f8f8;
    border-radius: 4px 4px 4px 4px;
    padding: 16px 50px 16px 16px;
  }

  .card-main,
  .card-main-prohibit,
  .card-main-ck,
  .card-main-hover,
  .card-main-hover-ck {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .card-top {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      > span {
        display: block;
        &:first-child {
          color: #9a9d9e;
          margin-bottom: 8px;
          font-size: 14px;
        }
      }
    }
    .card-down,
    .card-down-prohibit {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      > span {
        display: block;
        &:first-child {
          color: #9a9d9e;
          margin-bottom: 8px;
          font-size: 14px;
        }
      }
    }
    .card-down-prohibit {
      color: #9a9d9e;
    }
    .checked-x,
    .checked-prohibit {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 14px;
      height: 14px;
      background: #ffffff;
      border-radius: 100%;
      border: 1px solid #e0e0e0;
      box-sizing: initial !important;
    }
    .checked-prohibit {
      background: #e8e8e8;
    }
    .checked-hover-x {
      position: absolute;
      top: 6px;
      right: 6px;
      background-color: #2861e2;
      border-radius: 100%;
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #2861e2;
      box-sizing: initial !important;
      &::after {
        content: '';
        width: 4px;
        height: 4px;
        background: #ffffff;
        display: block;
        border-radius: 100%;
      }
    }
    .checked-text {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      width: 42px;
      height: 18px;
      background: #2861e2;
      border-radius: 0px 4px 0px 6px;
      text-align: center;
      line-height: 17px;
    }
  }
}

.top-main {
  margin-top: 40px;
  &:first-child {
    margin-top: 0;
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 20px;
    &:before {
      content: '';
      width: 3px;
      height: 22px;
      background: #363f47;
      border-radius: 0px 3px 3px 0px;
      margin-right: 8px;
    }
  }
}
</style>
