<template>
  <el-dialog title="查看验证码" :visible.sync="dialogVisible" :before-close="() => $emit('update:dialogVisible', false)" width="460px">
    <div v-loading="loading">
      <div style="color: #f59a23">*请复制后提供给服务人员，服务人员开始服务和完成服务时使用微信扫描用户提供的二维码，输入验证码进行验证</div>
      <div class="codeText">
        <div>订单编号：{{ serviceNo }}</div>
        <div>确认开始服务验证码：{{ startCode ? startCode : '--' }}</div>
        <div>确认完成服务验证码：{{ completeCode ? completeCode : '--' }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="copyCode(`确认开始服务验证码：${startCode ? startCode : '--'},确认完成服务验证码：${completeCode ? completeCode : '--'}`)"
          >复制</el-button
        >
      </span>
    </div>
  </el-dialog>
</template>

<script>
import { getServicebgWriteNo } from '@/api/order'

export default {
  props: {
    serviceNo: {
      type: String,
      default: '',
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    serviceId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      startCode: '',
      completeCode: '',
      loading: false,
    }
  },
  watch: {
    dialogVisible: function (val) {
      if (val) {
        this.getCode()
      }
    },
  },
  methods: {
    getCode() {
      this.loading = true
      getServicebgWriteNo({ data: { serviceId: this.serviceId } })
        .then((res) => {
          this.loading = false
          if (res.data) {
            res.data.forEach((e) => {
              const writeOffType = e.writeOffType
              if (writeOffType === 2) {
                this.startCode = e.writeOffNo
              }
              if (writeOffType === 3) {
                this.completeCode = e.writeOffNo
              }
            })
          }
        })
        .catch(() => {
          this.loading = false
        })
    },

    copyCode(data) {
      // 存储传递过来的数据
      const OrderNumber = data
      // 创建一个input 元素
      // createElement() 方法通过指定名称创建一个元素
      const newInput = document.createElement('input')
      // 讲存储的数据赋值给input的value值
      newInput.value = OrderNumber
      // appendChild() 方法向节点添加最后一个子节点。
      document.body.appendChild(newInput)
      // 选中input元素中的文本
      // select() 方法用于选择该元素中的文本。
      newInput.select()
      // 执行浏览器复制命令
      //  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
      document.execCommand('Copy')
      this.$notify.success({ title: '成功', message: '复制成功', duration: 2000 })
      // 清空输入框
      newInput.remove()
      // 下面是element的弹窗 不需要的自行删除就好
    },
  },
}
</script>

<style lang="less" scoped>
.codeText {
  color: #7f7f7f;
  margin-top: 10px;
  line-height: 30px;
}
</style>
