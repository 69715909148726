var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticClass:"dropdown-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query.keyword),expression:"query.keyword"}],staticClass:"input",class:{ disabled: !_vm.isNeedEdit },attrs:{"type":"text","disabled":!_vm.isNeedEdit,"placeholder":"可输入姓名、手机号关键字搜索"},domProps:{"value":(_vm.query.keyword)},on:{"click":_vm.toggleDropdown,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.query, "keyword", $event.target.value)}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dropdownVisible),expression:"dropdownVisible"}],staticClass:"dropdown-list",style:({ top: _vm.top + 'px', right: _vm.right + 'px' }),on:{"scroll":function($event){return _vm.listScroll($event)}}},[(_vm.residentStore.length)?_c('div',{staticClass:"list-title"},[_vm._v("常驻人员")]):_vm._e(),_c('div',{staticClass:"dropdown-items"},_vm._l((_vm.residentStore),function(item,index){return _c('div',{key:index,staticClass:"dropdown-item",class:{
          'dropdown-item-selected': item.isSelect,
          'full-time': item.isFullTime,
          'part-time': !item.isFullTime,
        },on:{"click":function($event){return _vm.selectOption(item)}}},[_c('div',{staticClass:"dropdown-item-left"},[_c('el-image',{staticClass:"avatar",attrs:{"src":item.avatarUrl ? item.avatarUrl : require('@/static/default.png')}}),(item.total)?_c('el-tooltip',{attrs:{"effect":"dark","placement":"top"}},[_c('div',{staticStyle:{"width":"120px"},attrs:{"slot":"content"},slot:"content"},[_c('div',{staticStyle:{"font-weight":"bold","font-size":"14px"}},[_vm._v("今日已有"+_vm._s(item.total)+"个任务")]),_c('div',{staticStyle:{"margin-top":"10px","color":"rgba(255, 255, 255, 0.75)"}},[_vm._v("服务开始时间：")]),_c('div',{staticStyle:{"display":"flex","margin-top":"6px","flex-wrap":"wrap"}},_vm._l((item.serviceUserTaskTimeList),function(timeItem,i){return _c('div',{key:i,staticClass:"tooltip-time-item",staticStyle:{"padding":"4px 6px","border-radius":"2px","display":"inline-block","background":"rgba(233, 238, 249, 0.3)","color":"#ffffff","flex-shrink":"0","margin-top":"6px","width":"45%","text-align":"center","margin-right":"5%","box-sizing":"border-box"},style:({
                    background:
                      timeItem.value == 1 ? 'rgba(242,113,1,0.7)' : 'rgba(233, 238, 249, 0.3)',
                  })},[_vm._v(" "+_vm._s(timeItem.name)+" ")])}),0)]),_c('span',{staticClass:"brage"},[_vm._v(_vm._s(item.total))])]):_vm._e()],1),_c('div',{staticClass:"dropdown-item-right"},[_c('div',{staticClass:"dropdown-item-right-title"},[_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(item.userName))]),(item.isProminent)?_c('span',{staticClass:"text-icon twice-icon"},[_vm._v("主")]):_vm._e()]),_c('div',{staticClass:"dropdown-item-right-content"},_vm._l((item.customerLevelList || []),function(num,ind){return _c('span',{key:ind,staticClass:"grade-num",class:{
                orange: num['value'] > 0 && (num.name == 'A' || num.name == 'B'),
                blue: num['value'] > 0 && (num.name == 'C' || num.name == 'D'),
              }},[_vm._v(" "+_vm._s(num['value'] || 0)+" ")])}),0)]),(item.hasTask > 0)?_c('div',{staticClass:"red-dot"}):_vm._e()])}),0),(_vm.affiliatedStore.length)?_c('div',{staticClass:"list-title"},[_vm._v("关联人员")]):_vm._e(),_c('div',{staticClass:"dropdown-items"},_vm._l((_vm.affiliatedStore),function(item,index){return _c('div',{key:index,staticClass:"dropdown-item",class:{
          'dropdown-item-selected': item.isSelect,
          'full-time': item.isFullTime,
          'part-time': !item.isFullTime,
        },on:{"click":function($event){return _vm.selectOption(item)}}},[_c('div',{staticClass:"dropdown-item-left"},[_c('el-image',{staticClass:"avatar",attrs:{"src":item.avatarUrl ? item.avatarUrl : require('@/static/default.png')}}),(item.total)?_c('el-tooltip',{attrs:{"effect":"dark","placement":"top"}},[_c('div',{staticStyle:{"width":"120px"},attrs:{"slot":"content"},slot:"content"},[_c('div',{staticStyle:{"font-weight":"bold","font-size":"14px"}},[_vm._v("今日已有"+_vm._s(item.total)+"个任务")]),_c('div',{staticStyle:{"margin-top":"10px","color":"rgba(255, 255, 255, 0.75)"}},[_vm._v("服务开始时间：")]),_c('div',{staticStyle:{"display":"flex","margin-top":"6px","flex-wrap":"wrap"}},_vm._l((item.serviceUserTaskTimeList),function(timeItem,i){return _c('div',{key:i,staticClass:"tooltip-time-item",staticStyle:{"padding":"4px 6px","border-radius":"2px","display":"inline-block","background":"rgba(233, 238, 249, 0.3)","color":"#ffffff","flex-shrink":"0","margin-top":"6px","width":"45%","text-align":"center","margin-right":"5%","box-sizing":"border-box"},style:({
                    background:
                      timeItem.value == 1 ? 'rgba(242,113,1,0.7)' : 'rgba(233, 238, 249, 0.3)',
                  })},[_vm._v(" "+_vm._s(timeItem.name)+" ")])}),0)]),_c('span',{staticClass:"brage"},[_vm._v(_vm._s(item.total))])]):_vm._e()],1),_c('div',{staticClass:"dropdown-item-right"},[_c('div',{staticClass:"dropdown-item-right-title"},[_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(item.userName))]),(item.isProminent)?_c('span',{staticClass:"text-icon twice-icon"},[_vm._v("主")]):_vm._e()]),_c('div',{staticClass:"dropdown-item-right-content"},_vm._l((item.customerLevelList || []),function(num,ind){return _c('span',{key:ind,staticClass:"grade-num",class:{
                orange: num['value'] > 0 && (num.name == 'A' || num.name == 'B'),
                blue: num['value'] > 0 && (num.name == 'C' || num.name == 'D'),
              }},[_vm._v(" "+_vm._s(num['value'] || 0)+" ")])}),0)]),(item.hasTask > 0)?_c('div',{staticClass:"red-dot"}):_vm._e()])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"loadMore"},[_vm._v("加载中...")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.options.length == 0),expression:"options.length == 0"}],staticClass:"loadMore"},[_vm._v("无数据")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }