<template>
  <div ref="container" class="dropdown-container">
    <input
      v-model="query.keyword"
      class="input"
      :class="{ disabled: !isNeedEdit }"
      type="text"
      :disabled="!isNeedEdit"
      placeholder="可输入姓名、手机号关键字搜索"
      @click="toggleDropdown"
    />
    <div
      v-show="dropdownVisible"
      class="dropdown-list"
      :style="{ top: top + 'px', right: right + 'px' }"
      @scroll="listScroll($event)"
    >
      <div v-if="residentStore.length" class="list-title">常驻人员</div>
      <div class="dropdown-items">
        <div
          v-for="(item, index) in residentStore"
          :key="index"
          class="dropdown-item"
          :class="{
            'dropdown-item-selected': item.isSelect,
            'full-time': item.isFullTime,
            'part-time': !item.isFullTime,
          }"
          @click="selectOption(item)"
        >
          <div class="dropdown-item-left">
            <el-image
              class="avatar"
              :src="item.avatarUrl ? item.avatarUrl : require('@/static/default.png')"
            />
            <el-tooltip v-if="item.total" effect="dark" placement="top">
              <div slot="content" style="width: 120px">
                <div style="font-weight: bold; font-size: 14px">今日已有{{ item.total }}个任务</div>
                <div style="margin-top: 10px; color: rgba(255, 255, 255, 0.75)">服务开始时间：</div>

                <div style="display: flex; margin-top: 6px; flex-wrap: wrap">
                  <div
                    v-for="(timeItem, i) in item.serviceUserTaskTimeList"
                    :key="i"
                    class="tooltip-time-item"
                    style="
                      padding: 4px 6px;
                      border-radius: 2px;
                      display: inline-block;
                      background: rgba(233, 238, 249, 0.3);
                      color: #ffffff;
                      flex-shrink: 0;
                      margin-top: 6px;
                      width: 45%;
                      text-align: center;
                      margin-right: 5%;
                      box-sizing: border-box;
                    "
                    :style="{
                      background:
                        timeItem.value == 1 ? 'rgba(242,113,1,0.7)' : 'rgba(233, 238, 249, 0.3)',
                    }"
                  >
                    {{ timeItem.name }}
                  </div>
                </div>
              </div>
              <span class="brage">{{ item.total }}</span>
            </el-tooltip>
          </div>
          <div class="dropdown-item-right">
            <div class="dropdown-item-right-title">
              <span class="title-text">{{ item.userName }}</span>
              <!-- <span v-if="item.isFullTime === 0" class="text-icon twice-icon">主</span> -->
              <span v-if="item.isProminent" class="text-icon twice-icon">主</span>
            </div>
            <div class="dropdown-item-right-content">
              <span
                v-for="(num, ind) in item.customerLevelList || []"
                :key="ind"
                class="grade-num"
                :class="{
                  orange: num['value'] > 0 && (num.name == 'A' || num.name == 'B'),
                  blue: num['value'] > 0 && (num.name == 'C' || num.name == 'D'),
                }"
              >
                {{ num['value'] || 0 }}
              </span>
            </div>
          </div>

          <!-- <div v-if="item.hasTask <= 0" class="selected-icon-container">
            <i class="el-icon-check" style="color: #fff" />
          </div> -->

          <div v-if="item.hasTask > 0" class="red-dot" />
        </div>
      </div>
      <div v-if="affiliatedStore.length" class="list-title">关联人员</div>
      <div class="dropdown-items">
        <div
          v-for="(item, index) in affiliatedStore"
          :key="index"
          class="dropdown-item"
          :class="{
            'dropdown-item-selected': item.isSelect,
            'full-time': item.isFullTime,
            'part-time': !item.isFullTime,
          }"
          @click="selectOption(item)"
        >
          <div class="dropdown-item-left">
            <el-image
              class="avatar"
              :src="item.avatarUrl ? item.avatarUrl : require('@/static/default.png')"
            />
            <el-tooltip v-if="item.total" effect="dark" placement="top">
              <div slot="content" style="width: 120px">
                <div style="font-weight: bold; font-size: 14px">今日已有{{ item.total }}个任务</div>
                <div style="margin-top: 10px; color: rgba(255, 255, 255, 0.75)">服务开始时间：</div>

                <div style="display: flex; margin-top: 6px; flex-wrap: wrap">
                  <div
                    v-for="(timeItem, i) in item.serviceUserTaskTimeList"
                    :key="i"
                    class="tooltip-time-item"
                    style="
                      padding: 4px 6px;
                      border-radius: 2px;
                      display: inline-block;
                      background: rgba(233, 238, 249, 0.3);
                      color: #ffffff;
                      flex-shrink: 0;
                      margin-top: 6px;
                      width: 45%;
                      text-align: center;
                      margin-right: 5%;
                      box-sizing: border-box;
                    "
                    :style="{
                      background:
                        timeItem.value == 1 ? 'rgba(242,113,1,0.7)' : 'rgba(233, 238, 249, 0.3)',
                    }"
                  >
                    {{ timeItem.name }}
                  </div>
                </div>
              </div>
              <span class="brage">{{ item.total }}</span>
            </el-tooltip>
          </div>
          <div class="dropdown-item-right">
            <div class="dropdown-item-right-title">
              <span class="title-text">{{ item.userName }}</span>
              <span v-if="item.isProminent" class="text-icon twice-icon">主</span>
            </div>
            <div class="dropdown-item-right-content">
              <span
                v-for="(num, ind) in item.customerLevelList || []"
                :key="ind"
                class="grade-num"
                :class="{
                  orange: num['value'] > 0 && (num.name == 'A' || num.name == 'B'),
                  blue: num['value'] > 0 && (num.name == 'C' || num.name == 'D'),
                }"
              >
                {{ num['value'] || 0 }}
              </span>
            </div>
          </div>

          <!-- <div v-if="item.hasTask <= 0" class="selected-icon-container">
            <i class="el-icon-check" style="color: #fff" />
          </div> -->

          <div v-if="item.hasTask > 0" class="red-dot" />
        </div>
      </div>
      <div v-show="loading" class="loadMore">加载中...</div>
      <div v-show="options.length == 0" class="loadMore">无数据</div>
    </div>
  </div>
</template>

<script>
import { getUserListPost } from '@/api/order'

export default {
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    dropdownVisible: false,
    search: '',
    options: [],
    top: '',
    right: '',
    query: {
      pageNumber: 1,
      pageSize: 10,
      keyword: '',
    },
    lastPage: false,
    loading: false,
  }),
  computed: {
    isNeedEdit() {
      const row = this.row
      const state = row.state
      if (state === 9) return false
      if (state <= 3 || state === 7) return true
      if (state === 4 && row.isAlwaysEdit) return true
      if (row.isAlwaysEdit) return true
      return false
    },
    residentStore() {
      return this.options.filter((v) => v.isResident === 1)
    }, // 常驻门店

    affiliatedStore() {
      // return this.options.filter((v) => true)
      return this.options.filter((v) => v.isResident === 2)
    }, // 关联门店
  },
  watch: {
    row: {
      handler(newVal) {
        if (newVal) {
          this.query.keyword = newVal.serviceUserName
        }
      },
      deep: true,
      immediate: true,
    },
    'query.keyword': {
      handler(newVal) {
        this.query.pageNumber = 1
        this.options = []
        this.getDataList()
      },
    },
  },
  mounted() {
    window.addEventListener('click', this.clickOutside)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.clickOutside)
  },
  methods: {
    toggleDropdown(e) {
      const el = document.elementFromPoint(e.clientX, e.clientY)
      this.right = document.documentElement.clientWidth - el.getBoundingClientRect().right
      const top = el.getBoundingClientRect().top + el.getBoundingClientRect().height
      this.top = [0, 1].includes(this.index) ? top : top - 320
      if (!this.dropdownVisible) {
        this.query.keyword = ''
        if (!this.row.serviceUserName) {
          this.query.pageNumber = 1
          this.options = []
          this.getDataList()
        }
      }

      this.dropdownVisible = !this.dropdownVisible
    },
    clickOutside(event) {
      if (!this.$refs.container.contains(event.target)) {
        this.dropdownVisible = false
        this.query.keyword = this.row.serviceUserName || ''
      }
    },
    selectOption(option) {
      this.$emit('change', {
        serviceUserId: option.userId,
        serviceUserName: option.userName,
      })
      if (option.userId === this.row.serviceUserId) {
        this.query.keyword = option.userName
      }

      this.dropdownVisible = false
    },
    async getDataList() {
      this.loading = true
      const params = {
        data: {
          baseProductType: this.row.baseProductType,
          serviceId: this.row.serviceId,
          keyword: this.query.keyword,
        },
        pageNumber: this.query.pageNumber,
        pageSize: this.query.pageSize,
      }

      const res = await getUserListPost(params)
      if (res.success) {
        this.loading = false
        this.lastPage = res.data.lastPage
        this.options = [...this.options, ...(res.data.content || [])]
        console.log(this.options, ']]]]]]]]]]]]]]]]]]]')
        this.options.forEach((el) => {
          if (el.userId === this.row.serviceUserId) {
            el.isSelect = true
          } else {
            el.isSelect = false
          }
        })
      }
    },
    handleArr(arr) {
      const newArr = arr.map((el) => el.postName)
      return newArr.join('、')
    },
    listScroll(e) {
      if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
        console.log('触底了')
        if (!this.loading && !this.lastPage) {
          this.query.pageNumber++
          this.getDataList()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.full-time {
  background: #e5ecfc;
}
.part-time {
  background: #f4f4f4;
}
.mian-border {
  border: 1px solid #ef6e35;
  // background: #F4F4F4;
}
.part-time-border {
  border: 1px solid #2861e2;
  background: #e9effc;
}
.list-title {
  font-size: 16px;
  font-weight: 500;
  color: #111111;
  margin-bottom: 10px;
}
.text-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  margin-left: 4px;
  font-size: 10px;
  font-weight: 900;
  border-radius: 1px 1px 1px 1px;
}
.twice-icon {
  width: 14px;
  background: #ffeadf;
  border: 0.7px solid #ff7027;
  color: #ff7027;
}
.title-text {
  max-width: 62px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dropdown-container {
  position: relative;
}

.dropdown-list {
  position: fixed;
  /* top: 100%; */
  /* left: 0; */
  right: 0;

  border-radius: 4px;
  border: 1px solid #e4e7ed;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
  background: white;
  border-top: none;
  z-index: 1000;
  padding: 20px 4px 4px 20px;
  box-sizing: border-box;
  width: 600px;
  height: 289px;
  align-content: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #e0e3e7;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }
}
.dropdown-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.dropdown-item {
  /* width: calc(33.33% - 10px); */
  /* margin-bottom: 5px; */
  flex-basis: calc((100% - 48px) / 3);
  height: calc((100% - 48px) / 3);
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  // background: #f8f8f8;
  border-radius: 4px;
  padding: 16px 16px 16px 12px;
  display: flex;
  position: relative;
  align-items: center;

  .dropdown-item-left {
    position: relative;
    height: 40px;
    width: 40px;
    margin-right: 12px;

    .avatar {
      height: 40px;
      width: 40px;
      border-radius: 20px;
    }

    .brage {
      position: absolute;
      top: -15px;
      right: -10px;
      background: #2861e2;
      border-radius: 16px;
      border: 2px solid #fff;
      color: #fff;
      font-size: 20px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(0.5);
    }
  }

  .dropdown-item-right {
    display: flex;
    flex-direction: column;

    .dropdown-item-right-title {
      font-size: 14px;
      color: #333333;
      font-weight: bold;
      display: -webkit-box;
      display: flex;
      align-items: center;
    }

    .dropdown-item-right-content {
      .grade-num {
        background: #cccccc;
        color: #fff;
        width: 14px;
        height: 14px;
        border-radius: 2px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }

      .grade-num + .grade-num {
        margin-left: 3px;
      }

      .orange {
        background: #ff810f;
      }

      .blue {
        background: #2861e2;
      }
    }
  }

  .red-dot {
    position: absolute;
    background: #f33f3f;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    right: 4px;
    top: 4px;
  }
}

.dropdown-item-selected {
  border: 1px solid #2861e2;
  // background: #e9effc;

  .selected-icon-container {
    width: 16px;
    height: 12px;
    border-radius: 0px 0px 0px 6px;
    background: #2861e2;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.loadMore {
  text-align: center;
  color: #999;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type='text'] {
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  color: #606266;
  outline: 0;
  padding: 0 15px;
  height: 28px;
  line-height: 28px;
}

input[type='text']:focus {
  outline: none;
}

input[type='text']::-webkit-input-placeholder {
  color: #c7c9cf;
}

.disabled {
  cursor: not-allowed;
}
</style>
