<template>
  <el-dialog
    class="refuse-order-container"
    :title="popupsTitle"
    :visible="showState"
    width="600px"
    top="30vh"
    @close="hidePopups()"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="拒绝原因" prop="reason">
        <el-input v-model="form.reason" type="textarea" :rows="6"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="hidePopups">取消</el-button>
      <el-button type="primary" :loading="submitLoadingStatus" @click="submitForm()"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { refuseOrder } from '@/api/order'
export default {
  props: {
    popupsTitle: {
      type: String,
      default: '',
    },
    showState: {
      type: Boolean,
      default: false,
    },
    currentData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      submitLoadingStatus: false,
      form: {
        reason: undefined,
      },
      rules: { reason: [{ required: true, message: '请输入拒绝原因', trigger: 'blur' }] },
    }
  },

  methods: {
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoadingStatus = true
          const formData = JSON.parse(JSON.stringify(this.form))
          formData.serviceId = this.currentData.serviceId
          refuseOrder({ data: formData })
            .then(() => {
              this.$notify.success({ title: '成功', message: '拒绝成功', duration: 2000 })
              if (this.currentData.inType === 'list') {
                this.$parent.getTableList()
              } else {
                this.$parent.getData()
              }
              this.hidePopups()
              this.submitLoadingStatus = false
            })
            .catch(() => {
              this.submitLoadingStatus = false
            })
        }
      })
    },
    hidePopups() {
      this.$emit('hidePopups')
      this.$refs.form.resetFields()
      Object.assign(this.$data.form, this.$options.data().form)
    },
  },
}
</script>

<style lang="scss" scoped>
.refuse-order-container {
  ::v-deep .el-dialog__title {
    font-size: 16px;
  }
  ::v-deep .el-dialog__body {
    padding: 24px 40px 16px 40px;
  }
  ::v-deep .el-dialog__footer {
    padding: 12px 24px;
    border-top: 1px solid #ededed;
  }
  ::v-deep .dialog-footer {
    text-align: right;
  }
}
</style>
