<template>
  <div class="wrap">
    <el-dialog
      title="请选择服务人员"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="950px"
      :before-close="handleClose"
    >
      <div v-if="currentData.subStatus == 2">
        <div class="desc">服务时间：{{ currentData.appointmentShootDatetime }}</div>
        <div class="desc">服务地址：{{ currentData.appointmentStroeAddress }}</div>
        <div class="desc">
          预约信息：{{ currentData.contactPersonName || '--' }}&emsp;{{
            currentData.contactPhoneNumber || '--'
          }}
          <span v-if="currentData.customerLevelName" class="customer-grade">{{
            currentData.customerLevelName
          }}</span>
        </div>
      </div>
      <div class="line-title">
        <div class="line"></div>
        安排详情：
      </div>

      <el-table
        v-loading="loading"
        size="mini"
        border
        :data="tableData"
        style="width: 100%"
        max-height="400"
      >
        <el-table-column prop="serviceItemNo" label="子服务单ID" width="210"></el-table-column>
        <el-table-column prop="baseServiceType" label="服务类型" width="180">
          <template slot-scope="scope">{{ scope.row.baseProductTypeName }}</template>
        </el-table-column>
        <el-table-column prop="name" label="服务内容">
          <template slot-scope="scope">
            <span v-if="scope.row.baseProductType == 8">
              {{ scope.row.entityProductInfo.productName }} | {{ scope.row.baseProductName }} |
              {{ scope.row.baseProductTypeName }} | 1 {{ scope.row.entityProductInfo.priceUnit }} |
              1
              {{ scope.row.priceUnit }}
            </span>
            <span v-else
              >{{ scope.row.baseProductName }} | {{ scope.row.baseProductTypeName }} |
              {{ scope.row.number }} | {{ scope.row.priceUnit }}</span
            >
          </template>
        </el-table-column>

        <el-table-column label="安排服务人员" width="240">
          <template slot-scope="scope">
            <CustomDropdown
              :row="scope.row"
              :index="scope.$index"
              @change="currentData.status !== 7 ? selectChange($event, scope.row) : ''"
            />
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="{ row }">
            <el-button
              v-if="row.adminButtons && row.adminButtons.delBtn"
              type="text"
              style="color: #7f90b7"
              @click="remove(row)"
              >删除该项</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button v-if="currentData.status !== 7" :loading="btnLoading" type="primary" @click="ok"
          >完成安排</el-button
        >
      </span>
      <el-dialog
        :visible.sync="delVisible"
        width="420px"
        top="40vh"
        :modal="false"
        :show-close="false"
        class="delDialog"
      >
        <div class="delHeader">
          <span class="title"> <i class="el-icon-warning icon"></i> 确认删除该项服务吗？ </span>
          <i class="el-icon-close close"></i>
        </div>
        <div class="hint">删除后如果还需安排该岗位，可以通过加购添加</div>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" plain @click="delVisible = false">取消</el-button>
          <el-button type="danger" size="mini" @click="sure">删除</el-button>
        </div>
        <!-- <el-button slot="reference">删除</el-button> -->
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { getServiceItemsListByServiceId, batchDispatch, deleteByItemId } from '@/api/order'

import CustomDropdown from './CustomDropdown.vue'

export default {
  components: { CustomDropdown },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    baseProductType: {
      type: [Number, String],
      default: '',
    },
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableData: [],
      form: {},
      btnLoading: false,
      loading: false,
      delVisible: false,
      row: null,
    }
  },
  mounted() {
    this.getServiceItemsListByServiceId()
  },
  methods: {
    // 移除
    remove(row) {
      this.delVisible = true
      this.row = row
    },
    sure() {
      deleteByItemId({ data: this.row.serviceItemId }).then((res) => {
        this.$message.success('删除成功')
        this.getServiceItemsListByServiceId()
        this.delVisible = false
      })
    },
    getServiceItemsListByServiceId() {
      this.loading = true
      const data = {
        serviceId: this.currentData.serviceId,
        // baseProductType: this.currentData.buttonV2Dto.repeatVideo ? this.baseProductType : '',
        baseProductType: '',
      }

      getServiceItemsListByServiceId({ data })
        .then((res) => {
          this.loading = false

          this.tableData = (res.data || []).map((el) => {
            const serviceUserId = el.serviceUserId
            return {
              ...el,
              isAlwaysEdit: !el.serviceUserId || serviceUserId === '0',
              serviceUserId: !el.serviceUserId || serviceUserId === '0' ? '' : el.serviceUserId,
            }
          })
        })
        .catch(() => {
          this.loading = false
        })
    },
    async ok() {
      try {
        const dispatchUserList = this.tableData.filter(
          (e) => e.serviceUserId !== '0' || (e.dispatchInfo && e.dispatchInfo.serviceUserId),
        )

        if (!dispatchUserList.length) {
          this.$message.warning('请至少选择一位服务人员')
          return
        }

        this.btnLoading = true

        const serviceUserList = dispatchUserList.map((e) => ({
          serviceItemsId: e.serviceItemId,
          serviceUserId: e.serviceUserId,
          serviceUserName: e.serviceUserName,
        }))

        let serviceUserStatus = 0
        const status = this.currentData.status
        if (this.currentData.serviceUserStatus === 0 && (status === 5 || status === 8))
          serviceUserStatus = 1

        const result = await batchDispatch({
          data: {
            serviceId: this.currentData.serviceId,
            serviceUserStatus,
            dispatchList: serviceUserList,
          },
        })

        console.log(result)

        this.$message({
          type: 'success',
          message: '安排成功',
        })

        this.btnLoading = false
        this.handleClose()
        this.$emit('reload')
      } catch (err) {
        this.btnLoading = false
      }
    },
    handleClose() {
      this.loading = false
      this.$emit('update:dialogVisible', false)
    },
    selectChange(e, row) {
      console.log(e)
      row.serviceUserId = e.serviceUserId
      row.serviceUserName = e.serviceUserName
      this.$set(this.tableData, this.tableData.indexOf(row), row)
    },
  },
}
</script>

<style lang="less" scoped>
.delDialog {
  .delHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .hint {
    font-size: 14px;
    color: #9a9d9e;
    padding: 18px;
    margin-left: 18px;
  }
  .title {
    font-size: 16px;
    color: #111111;
    .icon {
      font-size: 22px;
      color: #eca000;
      margin-right: 15px;
    }
  }
  .close {
    cursor: pointer;
    color: #9a9d9e;
  }
  ::v-deep .el-dialog__header {
    height: 0;
    padding: 0;
  }
  ::v-deep.el-dialog__body {
    border-radius: 6px 6px 6px 6px;
  }
}
.wrap {
  padding: 0;
}

::v-deep.el-dialog__footer {
  text-align: right !important;
}

.box {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #eee;
}

.desc {
  padding: 5px;

  .customer-grade {
    display: inline-flex;
    font-size: 12px;
    width: 14px;
    height: 14px;
    background: #f33f3f;
    color: #fff;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
  }
}

.line-title {
  padding-left: 5px;
  line-height: 30px;
}

::v-deep.el-scrollbar__wrap {
  overflow-x: hidden;
}

.loading {
  text-align: center;
  padding: 10px;
}
</style>
