<template>
  <!-- <div> -->
  <el-dialog :title="title" :visible.sync="visible" width="337px">
    <div>
      <el-form ref="form" :model="form" :rules="rules" label-width="70px">
        <el-form-item label="手机号" prop="sharePhoneNumber">
          <el-input v-model="form.sharePhoneNumber" placeholder="请输入账号手机号" />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" :loading="loading" @click="share">分享</el-button>
    </span>
  </el-dialog>
  <!-- </div> -->
</template>

<script>
import { shareServiceEva } from '@/api/order'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    serviceId: {
      type: String,
      default: '',
    },
  },
  data() {
    // 电话号码校验
    const checkTel = (rule, value, callback) => {
      const phoneNumberRegex = /^1[3456789]\d{9}$/
      if (!value) return callback(new Error('请输入电话号码'))
      if (!phoneNumberRegex.test(value)) return callback(new Error('请输入正确电话号码'))
      callback()
    }
    return {
      title: '评价分享到指定账户',
      form: {
        sharePhoneNumber: '',
      },
      rules: {
        sharePhoneNumber: { required: true, validator: checkTel, trigger: 'blur' },
      },
      loading: false,
    }
  },
  methods: {
    // 分享
    share() {
      this.$refs.form.validate((valid) => {
        if (!valid) return false
        this.loading = true
        const params = { ...this.form, serviceId: this.serviceId }
        shareServiceEva({ data: params })
          .then(({ success }) => {
            if (success) {
              this.$message.success('分享成功')
              this.cancel()
              this.$emit('reload')
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      })
    },
    cancel() {
      this.$refs.form.resetFields()
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .el-dialog__footer {
  border-top: 1px solid #ededed;
  padding: 12px 24px;
}
</style>
